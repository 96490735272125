@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Public+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;1,300;1,400&display=swap");

@font-face {
  font-family: gobold;
  src: url("./assets/gobold.hollow-bold.otf");
}

@layer components {
  .text-stroke {
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
  }

  .stroke-blue {
    background: linear-gradient(
      90.01deg,
      #2abb98 -1.3%,
      rgba(217, 217, 217, 0) 100.91%
    );
  }

  .stroke-red {
    background: linear-gradient(
      90.01deg,
      #ff0000 -1.3%,
      rgba(217, 217, 217, 0) 100.91%
    );
  }

  .stroke-black {
    background: linear-gradient(
      90.01deg,
      #000000 -1.3%,
      rgba(217, 217, 217, 0) 100.91%
    );
  }

  .work-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@layer base {
  .font-bebas {
    font-family: "Bebas Neue", cursive;
  }

  .font-jakarta {
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  .font-dm {
    font-family: "DM Sans", sans-serif;
  }

  .font-gobold {
    font-family: "gobold", sans-serif;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

body {
  overflow-x: hidden;
}

.txt-shadow {
  text-shadow: 0px 20px 17px rgba(0, 0, 0, 0.2);
}

.card-shadow {
  box-shadow: 5.4996px 5.4996px 3.6664px #000000;
}


.parallax-outer {
  overflow: hidden;
  width: 100vw;
}

.parallax {
  height: 100vh;
  width: 100%;
  position: relative;
}

.parallax img {
  position: absolute;
}


.mountain-3{
  /* width: 100%; */
  /* bottom: -10%; */
  z-index: 1;
}

.mountain-2 {
  /* bottom: -60%; */
  z-index: 2;
}

.mountain-1 {
  /* bottom: -80%; */
  z-index: 3;
}

@-webkit-keyframes flip-horizontal-bottom {
  1000% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  0% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@keyframes flip-horizontal-bottom {
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  0% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2 both;
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2 both;
}