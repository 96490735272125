.nav-links {


    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #FFFFFF;
}

/* 
.rectangle-bg-image {
    background-image: url("./rectangle.png");
    background-repeat: no-repeat;
    background-position: center;background-size:contain;
} */
/* .rectangle-text {
    position: absolute;
    height: 121px;
    left: 69px;
    top: 1340px;
    width: 50vw;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 1;


    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #FFFFFF;
} */

.phoneCard-container {
    box-sizing: border-box;
    overflow: hidden;
    width: 464.11px;
    height: 616.98px;
    position: relative;
    background: #FFFFFF;
    border: 4.02411px solid #000000;
    border-radius: 36.6889px 36.6889px 0px 0px;
    /* flex: none;
order: 0;
flex-grow: 0; */
}

.phoneCard-iphone {
    position: absolute;
    /* width: 262.64px;
        height: 535.33px; */
    /* left: 126.76px;
        top: 382.62px; */
    bottom: 0;
    right: 0;
    z-index: 1;
    /* transform: rotate(-19deg); */
}

.phoneCard-title {
    /* position: absolute; */
    /* width: 381.62px;
        height: 77px; */
    /* left: 41.58px;
        top: 26.83px; */
    margin-top: 20px;
    padding: 20px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 38px;
    /* or 96% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #000000;
}

.phoneCard-text {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    /* or 123% */
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #333333;
}

.phoneCard-circle {
    position: absolute;
    width: 745.8px;
    height: 745.8px;
    left: -140.84px;
    top: 430.58px;
    border-radius: 50%;
    box-shadow: 0px 0px 16.0964px rgba(0, 0, 0, 0.25);
}

.phoneCard-fullreport {
    width: 464.11px;
    height: 40.91px;

    background: #000000;
    border-radius: 0px 0px 6.11481px 6.11481px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.services-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
margin-top: 20vh;
margin-left: 5vw;
    /* or 148% */

    display: flex;
    text-transform: uppercase;

    color: #000000;
}